import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const generateDownloadUrl = async (canvas) => {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    }, 'image/png');
  });
};

export const downloadSticker = async (canvas, filename) => {
  try {
    const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.click();
    setTimeout(() => URL.revokeObjectURL(url), 100);
  } catch (err) {
    console.error('Download error:', err);
    throw new Error('Failed to download image');
  }
};

export const downloadAllStickers = async (files, canvasRefs, customFilenames) => {
  const completedFiles = files.filter((f) => f.status === 'complete');
  if (completedFiles.length === 0) {
    throw new Error('No completed files to download');
  }

  const zip = new JSZip();

  for (const fileObj of completedFiles) {
    const canvas = canvasRefs.current.get(fileObj.id);
    if (!canvas) continue;

    try {
      const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));
      const filename = customFilenames[fileObj.id] || `sticker-${fileObj.id}.png`;
      zip.file(filename, blob);
    } catch (err) {
      console.error('Error adding file to ZIP:', err);
    }
  }

  try {
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'stickers.zip');
  } catch (err) {
    console.error('Error generating ZIP:', err);
    throw new Error('Failed to download ZIP file');
  }
};