import React, { useEffect, useRef, useState } from 'react';
import UploadArea from './components/UploadArea';
import PreviewSection from './components/PreviewSection';
import { processImage } from './utils/imageProcessing';
import { downloadSticker, downloadAllStickers } from './utils/fileUtils';

const App = () => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [customFilenames, setCustomFilenames] = useState({});
  const canvasRefs = useRef(new Map());

  const processFile = (file) => {
    if (!file.type.startsWith('image/png')) {
      setError('Please upload PNG images with transparency');
      return null;
    }

    const fileObj = {
      id: Date.now() + Math.random(),
      file,
      url: URL.createObjectURL(file),
      status: 'processing',
    };

    setCustomFilenames((prev) => ({
      ...prev,
      [fileObj.id]: file.name,
    }));

    return fileObj;
  };

  const handleFiles = (newFiles) => {
    setError('');
    const processed = Array.from(newFiles).map(processFile).filter(Boolean);
    setFiles((prev) => [...prev, ...processed]);
  };

  const handleFileChange = (event) => {
    handleFiles(event.target.files);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  const removeFile = (id) => {
    setFiles((prev) => {
      const newFiles = prev.filter((f) => f.id !== id);
      const removedFile = prev.find((f) => f.id === id);
      if (removedFile) {
        URL.revokeObjectURL(removedFile.url);
      }
      return newFiles;
    });
    setCustomFilenames((prev) => {
      const newFilenames = { ...prev };
      delete newFilenames[id];
      return newFilenames;
    });
  };

  const handleDownloadClick = async (id) => {
    const canvas = canvasRefs.current.get(id);
    if (!canvas) return;

    const filename = customFilenames[id] || `sticker-${id}.png`;
    try {
      await downloadSticker(canvas, filename);
    } catch (err) {
      console.error('Download error:', err);
      setError('Failed to download image');
    }
  };

  const handleDownloadAllClick = async () => {
    try {
      await downloadAllStickers(files, canvasRefs, customFilenames);
    } catch (err) {
      console.error('Download all error:', err);
      setError(err.message);
    }
  };

  const handleFilenameChange = (id, newName) => {
    const sanitizedName = newName.replace(/\.png$/, '');
    setCustomFilenames((prev) => ({
      ...prev,
      [id]: `${sanitizedName}.png`,
    }));
  };

  useEffect(() => {
    files.forEach((fileObj) => {
      if (fileObj.status !== 'processing') return;

      const canvas = canvasRefs.current.get(fileObj.id);
      if (!canvas) return;

      const img = new Image();
      img.onload = () => {
        try {
          processImage(canvas, img);
          setFiles((prev) =>
            prev.map((f) =>
              f.id === fileObj.id ? { ...f, status: 'complete' } : f
            )
          );
        } catch (err) {
          console.error('Processing error:', err);
          setError('Error processing image');
          setFiles((prev) =>
            prev.map((f) =>
              f.id === fileObj.id ? { ...f, status: 'error' } : f
            )
          );
        }
      };
      img.src = fileObj.url;
    });

    return () => {
      files.forEach((f) => URL.revokeObjectURL(f.url));
    };
  }, [files]);

  return (
    <div style={styles.container}>
      <div style={styles.inner}>
        <h2 style={styles.title}>Sticker Effect Generator</h2>
        <UploadArea
          onFilesUpload={handleFileChange}
          isDragging={isDragging}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          error={error}
        />
        {files.length > 0 && (
          <PreviewSection
            files={files}
            onDownloadAllClick={handleDownloadAllClick}
            onDownloadClick={handleDownloadClick}
            onRemoveClick={removeFile}
            onFilenameChange={handleFilenameChange}
            canvasRefs={canvasRefs}
            customFilenames={customFilenames}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '64rem',
    margin: '0 auto',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  inner: {
    padding: '1.5rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
  },
};

export default App;