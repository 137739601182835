const STROKE_WIDTH = 75;

export const resizeImage = (img) => {
  const MAX_WIDTH = 800;
  let width = img.width;
  let height = img.height;

  if (width > MAX_WIDTH) {
    height = Math.round((height * MAX_WIDTH) / width);
    width = MAX_WIDTH;
  }
  return { width, height };
};

export const cleanImageData = (data, width, height) => {
  const threshold = 10; // Alpha threshold for cleaning
  const result = new Uint8ClampedArray(data.length);
  
  // First pass: identify fully transparent areas
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const i = (y * width + x) * 4;
      const alpha = data[i + 3];
      
      // If pixel is nearly transparent or isolated, make it fully transparent
      if (alpha < threshold) {
        result[i] = 0;
        result[i + 1] = 0;
        result[i + 2] = 0;
        result[i + 3] = 0;
      } else {
        result[i] = data[i];
        result[i + 1] = data[i + 1];
        result[i + 2] = data[i + 2];
        result[i + 3] = alpha;
      }
    }
  }

  // Second pass: clean up isolated pixels
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      const i = (y * width + x) * 4;
      if (result[i + 3] > 0) {
        // Check surrounding pixels
        let transparentNeighbors = 0;
        for (let dy = -1; dy <= 1; dy++) {
          for (let dx = -1; dx <= 1; dx++) {
            if (dx === 0 && dy === 0) continue;
            const ni = ((y + dy) * width + (x + dx)) * 4;
            if (result[ni + 3] === 0) {
              transparentNeighbors++;
            }
          }
        }
        // If pixel is mostly surrounded by transparent pixels, remove it
        if (transparentNeighbors > 6) {
          result[i] = 0;
          result[i + 1] = 0;
          result[i + 2] = 0;
          result[i + 3] = 0;
        }
      }
    }
  }
  
  return result;
};

export const processImage = (canvas, img) => {
  const { width, height } = resizeImage(img);
  const ctx = canvas.getContext('2d');

  canvas.width = width + (STROKE_WIDTH * 2);
  canvas.height = height + (STROKE_WIDTH * 2);

  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = width;
  tempCanvas.height = height;
  const tempCtx = tempCanvas.getContext('2d');
  tempCtx.drawImage(img, 0, 0, width, height);

  const imageData = tempCtx.getImageData(0, 0, width, height);
  const cleanedData = cleanImageData(imageData.data, width, height);
  const cleanedImageData = new ImageData(cleanedData, width, height);
  tempCtx.putImageData(cleanedImageData, 0, 0);

  const maskCanvas = document.createElement('canvas');
  maskCanvas.width = canvas.width;
  maskCanvas.height = canvas.height;
  const maskCtx = maskCanvas.getContext('2d');

  maskCtx.beginPath();

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const i = (y * width + x) * 4;
      if (cleanedData[i + 3] > 0) {
        const hasTransparentNeighbor = (
          x === 0 || y === 0 || x === width - 1 || y === height - 1 ||
          cleanedData[(((y - 1) * width + x) * 4) + 3] === 0 ||
          cleanedData[(((y + 1) * width + x) * 4) + 3] === 0 ||
          cleanedData[((y * width + x - 1) * 4) + 3] === 0 ||
          cleanedData[((y * width + x + 1) * 4) + 3] === 0
        );

        if (hasTransparentNeighbor) {
          maskCtx.lineTo(x + STROKE_WIDTH, y + STROKE_WIDTH);
        }
      }
    }
  }

  maskCtx.closePath();
  maskCtx.fillStyle = 'white';
  maskCtx.fill();
  maskCtx.strokeStyle = 'white';
  maskCtx.lineWidth = STROKE_WIDTH;
  maskCtx.lineJoin = 'round';
  maskCtx.lineCap = 'round';
  maskCtx.stroke();
  maskCtx.drawImage(tempCanvas, STROKE_WIDTH, STROKE_WIDTH);

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(maskCanvas, 0, 0);
};