import React from 'react';
import { Upload } from 'lucide-react';

const UploadArea = ({ onFilesUpload, isDragging, onDragEnter, onDragOver, onDragLeave, onDrop, error }) => {
  return (
    <div style={styles.uploadArea}>
      <label 
        style={styles.dropzone(isDragging)}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div style={styles.uploadContent}>
          <Upload style={styles.uploadIcon} />
          <p style={styles.uploadText}>
            <span style={{fontWeight: 600}}>Click to upload</span> or drag and drop
          </p>
          <p style={styles.uploadSubtext}>PNG files with transparency</p>
        </div>
        <input 
          type="file" 
          style={styles.hiddenInput}
          accept="image/png"
          onChange={onFilesUpload}
          multiple
        />
      </label>
      {error && <p style={styles.error}>{error}</p>}
    </div>
  );
};

const styles = {
  uploadArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  dropzone: (isDragging) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '12rem',
    border: '2px dashed',
    borderColor: isDragging ? '#3B82F6' : '#E5E7EB',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    transition: 'all 0.2s',
    backgroundColor: isDragging ? '#EFF6FF' : '#F9FAFB',
  }),
  uploadContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem',
    textAlign: 'center',
  },
  uploadIcon: {
    width: '3rem',
    height: '3rem',
    marginBottom: '1rem',
    color: '#9CA3AF',
  },
  uploadText: {
    marginBottom: '0.5rem',
    fontSize: '1.125rem',
  },
  uploadSubtext: {
    fontSize: '0.875rem',
    color: '#6B7280',
  },
  hiddenInput: {
    display: 'none',
  },
  error: {
    marginTop: '0.5rem',
    fontSize: '0.875rem',
    color: '#EF4444',
  },
};

export default UploadArea;