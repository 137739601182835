import React from 'react';
import PreviewItem from './PreviewItem';
import { Save } from 'lucide-react';

const PreviewSection = ({ files, onDownloadAllClick, onDownloadClick, onRemoveClick, onFilenameChange, canvasRefs, customFilenames }) => {
  return (
    <div style={styles.previewSection}>
      <button
        onClick={onDownloadAllClick}
        style={styles.downloadAllButton}
        disabled={!files.some(f => f.status === 'complete')}
      >
        <Save size={16} />
        Download All
      </button>
      
      <div style={styles.previewScroll}>
        <div style={styles.previewGrid}>
          {files.map((fileObj) => (
            <PreviewItem
              key={fileObj.id}
              fileObj={fileObj}
              onDownloadClick={onDownloadClick}
              onRemoveClick={onRemoveClick}
              onFilenameChange={onFilenameChange}
              canvasRef={(el) => el && canvasRefs.current.set(fileObj.id, el)}
              customFilename={customFilenames[fileObj.id]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  previewSection: {
    marginTop: '1.5rem',
    border: '1px solid #E5E7EB',
    borderRadius: '0.375rem',
    padding: '1rem',
  },
  previewScroll: {
    height: '500px',
    overflowY: 'auto',
  },
  previewGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '1rem',
  },
  downloadAllButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#3B82F6',
    color: 'white',
    borderRadius: '0.375rem',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '1rem',
    fontSize: '0.875rem',
    fontWeight: '500',
  },
};

export default PreviewSection;