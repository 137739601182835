import React from 'react';
import { Download, X, Loader } from 'lucide-react';

const PreviewItem = ({ fileObj, onDownloadClick, onRemoveClick, onFilenameChange, canvasRef, customFilename }) => {
  return (
    <div style={styles.previewItem}>
      <div style={styles.previewCard}>
        <div style={styles.previewContent}>
          <input
            type="text"
            style={styles.filenameInput}
            placeholder="Enter filename"
            defaultValue={customFilename || fileObj.file.name}
            onBlur={(e) => onFilenameChange(fileObj.id, e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onFilenameChange(fileObj.id, e.target.value);
              }
            }}
          />
          <div style={styles.canvasContainer}>
            <canvas 
              ref={canvasRef}
              style={styles.canvas}
            />
          </div>
          <div style={styles.fileInfo}>
            <p style={styles.fileName}>
              {fileObj.file.name}
            </p>
            <div style={styles.buttonGroup}>
              <button
                onClick={() => onDownloadClick(fileObj.id)}
                disabled={fileObj.status !== 'complete'}
                style={{
                  ...styles.button,
                  ...(fileObj.status !== 'complete' ? styles.buttonDisabled : {})
                }}
              >
                <Download style={styles.buttonIcon} />
              </button>
              <button
                onClick={() => onRemoveClick(fileObj.id)}
                style={styles.button}
              >
                <X style={styles.buttonIcon} />
              </button>
            </div>
          </div>
          {fileObj.status === 'processing' && (
            <div style={styles.processingOverlay}>
              <Loader style={{
                ...styles.loader,
                animation: 'spin 1s linear infinite',
              }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  previewItem: {
    position: 'relative',
  },
  previewCard: {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    border: '1px solid #E5E7EB',
    overflow: 'hidden',
  },
  previewContent: {
    padding: '1rem',
  },
  canvasContainer: {
    backgroundColor: '#1F2937',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
  canvas: {
    maxWidth: '100%',
    height: 'auto',
  },
  fileInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
  },
  fileName: {
    fontSize: '0.875rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    marginRight: '0.5rem',
  },
  buttonGroup: {
    display: 'flex',
    gap: '0.5rem',
  },
  button: {
    padding: '0.5rem',
    borderRadius: '0.375rem',
    border: '1px solid #E5E7EB',
    backgroundColor: 'white',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  buttonIcon: {
    width: '1rem',
    height: '1rem',
  },
  processingOverlay: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.5rem',
  },
  loader: {
    width: '2rem',
    height: '2rem',
    color: 'white',
  },
  filenameInput: {
    padding: '0.25rem',
    border: '1px solid #E5E7EB',
    borderRadius: '0.25rem',
    fontSize: '0.875rem',
    width: '100%',
    marginBottom: '0.5rem',
  },
};

export default PreviewItem;